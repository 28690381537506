import React, { Component } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PostList from '../components/PostList';

import '../style/style.css';

const CategoryTitle = styled.h1`
  margin-bottom: 0.2rem;
  font-size: 2.6rem;
`;

class BlogIndex extends Component {
  render() {
    const { data } = this.props;
    const posts = data.allMarkdownRemark.edges;

    // Create category list
    const categories = {};
    posts.forEach(edge => {
      const {
        node: {
          frontmatter: { category },
        },
      } = edge;

      if (category && category != null) {
        if (!categories[category]) {
          categories[category] = [];
        }
        if (!edge.node.frontmatter.tags.includes('draft')) {
          categories[category].push(edge);
        }
      }
    });

    const categoryList = [];

    for (var key in categories) {
      categoryList.push([key, categories[key]]);
    }

    categoryList.sort();

    return (
      <Layout>
        <SEO
          title="Allar færslur"
          keywords={[`hjólreiðar`, `breiðablik`, `ic8`]}
        />

        <main>
          {categoryList.map(item => (
            <section key={item[0]}>
              <CategoryTitle>{item[0]}</CategoryTitle>
              <PostList posts={item[1]} />
            </section>
          ))}
        </main>
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { ne: "draft" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 160)

          frontmatter {
            slug
            date(formatString: "MMMM DD, YYYY")
            title
            author
            category
            tags
          }
        }
      }
    }
  }
`;
